import { useRef } from "react";
import Switch from "../modules/swich";
import AliceCarousel from "react-alice-carousel";

export default function Otziv(params) {
    let items = [
        <div className="otziv_slider_item">
            <div className="otziv_slider_item_content">
                <h3 className="t20 otziv_slider_item_content_author">
                    Дмитрий
                </h3>
                <p className="t20 otziv_slider_item_content_text">
                    Первое, что меня поразило — это красота
                    и масштаб города. Красноярск расположен
                    на реке Енисей, а его центр находится на
                    высоком берегу, откуда открывается
                    потрясающий вид на реку и окрестности.
                    Одной из главных достопримечательностей
                    Красноярска является заповедник
                    «Столбы». Это уникальный природный
                    комплекс, где можно насладиться красотой
                    сибирской тайги и почувствовать себя на
                    вершине мира. Виды, которые открываются
                    с вершины заповедника, просто
                    захватывают дух!
                </p>
            </div>
            <div
                className="otziv_slider_item_img"
                style={{
                    background: `url(/img/otziv/1.jpg)`,
                }}
            ></div>
        </div>,
        <div className="otziv_slider_item">
            <div className="otziv_slider_item_content">
                <h3 className="t20 otziv_slider_item_content_author">
                    Инна
                </h3>
                <p className="t20 otziv_slider_item_content_text">
                    Красноярск безумно красивый город,
                    обычно туристы приезжают сюда и первым
                    делом посещают «Столбы», на столбах
                    очень красивый вид, особенно летом, все
                    очень зеленое, безумно красивые
                    фотографии, просто чудо и очень
                    гостеприимный город, всех рады видеть!{" "}
                </p>
            </div>
            <div
                className="otziv_slider_item_img"
                style={{
                    background: `url(/img/otziv/2.jpg)`,
                }}
            ></div>
        </div>,
        <div className="otziv_slider_item">
            <div className="otziv_slider_item_content">
                <h3 className="t20 otziv_slider_item_content_author">
                    Вячеслав
                </h3>
                <p className="t20 otziv_slider_item_content_text">
                    Я только могу сказать, что отдых в
                    Красноярске в 2023 году был
                    незабываемым. Город предоставил мне
                    огромный выбор развлечений, от прогулок
                    по историческим местам до невероятных
                    приключений на природе. Все жители
                    города были очень гостеприимны и
                    дружелюбны, что создавало атмосферу
                    тепла и уюта. Я наслаждался каждым
                    моментом моего пребывания в Красноярске
                    и определенно рекомендую его как
                    прекрасное место для отдыха.
                </p>
            </div>
            <div
                className="otziv_slider_item_img"
                style={{
                    background: `url(/img/otziv/3.jpg)`,
                }}
            ></div>
        </div>,
        <div className="otziv_slider_item">
            <div className="otziv_slider_item_content">
                <h3 className="t20 otziv_slider_item_content_author">
                    Анна
                </h3>
                <p className="t20 otziv_slider_item_content_text">
                    Ну пришло время рассказать о нашем
                    отдыхе в городе Красноярске! Очень
                    развитый город, безумно красивые места,
                    и природа. Есть свой зоопарк, с
                    множеством разных животных. Очень
                    красивая набережная, довольно-таки
                    чистый город. Отдыхали летом. Есть куда
                    сходить, и на что посмотреть. Безусловно
                    рекомендую ... Ходили пешком, потому что
                    город радовал глаз.
                </p>
            </div>
            <div
                className="otziv_slider_item_img"
                style={{
                    background: `url(/img/otziv/4.jpg)`,
                }}
            ></div>
        </div>,
        <div className="otziv_slider_item">
            <div className="otziv_slider_item_content">
                <h3 className="t20 otziv_slider_item_content_author">
                    Игорь
                </h3>
                <p className="t20 otziv_slider_item_content_text">
                    Это просто невероятный город, тут просто
                    море достопримечательностей, даже сам по
                    себе очень красивый город, город поделен
                    на 2 части рекой Енисей, первый берег и
                    левый берег. Левый берег более обустроен
                    чем правый, вся красота именно на нем,
                    какие красивые мосты там, очень красивые
                    здания. Главной особенностью города
                    считаются столбы, это горы похожие на
                    какие-то фигуры, очень много туристов
                    именно в этом месте летом. Так же очень
                    большой зоопарк «Роев Ручей». Много
                    интересного, советую посетить этот
                    город.
                </p>
            </div>
            <div
                className="otziv_slider_item_img"
                style={{
                    background: `url(/img/otziv/5.jpg)`,
                }}
            ></div>
        </div>,
        <div className="otziv_slider_item">
            <div className="otziv_slider_item_content">
                <h3 className="t20 otziv_slider_item_content_author">
                    Эдуард
                </h3>
                <p className="t20 otziv_slider_item_content_text">
                    Красноярск насыщен практически всеми
                    видами отдыха в любое время года! Лето -
                    повод посетить прекрасный заповедник
                    «Столбы» и другие парки города. Есть
                    множество развлечений на открытом
                    воздухе - от картинга до пейнтбола и
                    лазертага. Осень - для Вас откроют свои
                    двери кафе и уютные рестораны. И, если
                    Вы замерзли - Вам обязательно подадут
                    плед. Зима - Бобровый Лог - все виды
                    зимних развлечений Вы найдете там. Ну и
                    наконец весна — это прогулки на
                    набережной города, когда холода уже
                    отступили и можно насладиться первыми
                    теплыми лучами.
                </p>
            </div>
            <div
                className="otziv_slider_item_img"
                style={{
                    background: `url(/img/otziv/8.jpg)`,
                }}
            ></div>
        </div>,
        <div className="otziv_slider_item">
            <div className="otziv_slider_item_content">
                <h3 className="t20 otziv_slider_item_content_author">
                    Вита
                </h3>
                <p className="t20 otziv_slider_item_content_text">
                    Здесь каждый откроет для себя что-то
                    интересное, будь то парки, музеи,
                    выставки, отдых за городом, зоопарк,
                    прогулка по вечернему городу или
                    активный отдых. Прекрасные
                    величественные «Столбы» особенно
                    красивы. Сибиряки добрые, отзывчивые.
                    Кухня на любой вкус и кошелёк. Много
                    достопримечательностей и памятников
                    архитектуры. Также можно прогуляться по
                    Енисею до ГЭС, местный красоты не
                    оставят никого равнодушным. Не важно кто
                    Вы по профессии и сколько у Вас денег на
                    счету, любите Вы спокойный отдых или
                    активный; Красноярск влюбит в себя с
                    первого взгляда и останется в Вашем
                    сердце навсегда.{" "}
                </p>
            </div>
            <div
                className="otziv_slider_item_img"
                style={{
                    background: `url(/img/otziv/6.jpg)`,
                }}
            ></div>
        </div>,
        <div className="otziv_slider_item">
            <div className="otziv_slider_item_content">
                <h3 className="t20 otziv_slider_item_content_author">
                    Алена
                </h3>
                <p className="t20 otziv_slider_item_content_text">
                    Красивый город. Хорошие дороги.
                    Площадки, парки, скверы. Советую
                    посетить зоопарк, там очень много
                    животных: жираф, попугаи, пингвины,
                    рыбы, обезьяны, почти все представители
                    кошачьих. Много различных растений,
                    очень красивы голубые ели. Еще
                    обязательно «Бобровый лог». Начну по
                    порядку: канатная дорога, 7 минут на
                    верх и столько же обратно, но эмоции...
                    Никаких денег не жалко. Природа на
                    «Столбах» — это неописуемо. Лес не
                    стронут, чистый воздух, красивые столбы.
                </p>
            </div>
            <div
                className="otziv_slider_item_img"
                style={{
                    background: `url(/img/otziv/7.jpg)`,
                }}
            ></div>
        </div>,
    ];
    const otziv = useRef(null);
    function moni(e) {
        if (e.item > 0) {
            document
                .querySelector(
                    ".otziv .oKrasnoyarsk_menu_switch_item.left "
                )
                .classList.remove("inactive");
        } else {
            document
                .querySelector(
                    ".otziv .oKrasnoyarsk_menu_switch_item.left"
                )
                .classList.add("inactive");
        }
        if (
            e.item >=
            document.querySelector(".otziv_slider_item")
                .length -
                1
        ) {
            document
                .querySelector(
                    ".otziv .oKrasnoyarsk_menu_switch_item.right "
                )
                .classList.add("inactive");
        } else {
            document
                .querySelector(
                    ".otziv .oKrasnoyarsk_menu_switch_item.right "
                )
                .classList.remove("inactive");
        }
    }
    return (
        <section className="otziv">
            <div className="container">
                <div className="otziv_menu flex">
                    <h2 className="h2">Отзывы</h2>
                    <Switch
                        refSlide={otziv}
                        classRod={'otziv'}
                    />
                </div>

                <div className="otziv_slider">
                    <AliceCarousel
                        key="kuhen"
                        autoWidth
                        mouseTracking
                        disableDotsControls
                        disableButtonsControls
                        ref={otziv}
                        items={items}
                        onSlideChanged={moni}
                    ></AliceCarousel>
                </div>
            </div>
        </section>
    );
}
