


export default function Info(params) {
    

    return (
        <section className="info">
            <div className="container">
                <h2 className="h2">
                Полезная информация
                </h2>

                <div className="info_content">
                    <a target="_blank" href="https://visitsiberia.info/poleznaya-informacziya-dlya-turistov.html" className="info_content_item">
                        <div style={{background:`url(/img/info/1.jpg)`}}  className="info_content_item_img">
                           
                        </div>
                        <p className="t18">
                        памятки и карты
                        </p>
                    </a>
                    <a target="_blank" href="https://visitsiberia.info/audiogidi-po-krasnoyary.html" className="info_content_item">
                        <div style={{background:`url(/img/info/2.jpg)`}} className="info_content_item_img">
                           
                        </div>
                        <p className="t18">
                        Аудиогиды по Красноярью
                        </p>
                    </a>
                    <a target="_blank" href="https://visitsiberia.info/chek-list-dlya-puteshestviya-po-severu-krasnoyarskogo-kraya.html" className="info_content_item">
                        <div style={{background:`url(/img/info/3.jpg)`}}  className="info_content_item_img">
                           
                        </div>
                        <p className="t18">
                        Чек-лист для путешествия по северу Красноярского края
                        </p>
                    </a>
                    <a target="_blank" href="https://visitsiberia.info/bukletyi-glavnyix-dostoprimechatelnostej-kraya.html" className="info_content_item">
                        <div style={{background:`url(/img/info/4.jpg)`}} className="info_content_item_img">
                           
                        </div>
                        <p className="t18">
                        буклеты достопримечательностей 
                        </p>
                    </a>
                </div>
            </div>
        </section>
    )
}