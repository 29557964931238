import React from 'react';
import ReactDOM from 'react-dom/client';
import "./css/style.css"
// import "./font/geometria/stylesheet.css"
import "./font/gothampro/stylesheet.css";
import Main from './main';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Main/>
  </React.StrictMode>
);


