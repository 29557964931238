import { useEffect } from "react";

export default function Switch({ refSlide, classRod }) {
    useEffect(() => {}, [refSlide]);
    const NextSlide = (e) => {
        if (
            !document
                .querySelector(
                    `.${classRod} .oKrasnoyarsk_menu_switch_item.right`
                )
                .classList.contains("inactive")
        ) {
            refSlide?.current?.slideNext(e);
        }
    };
    const PrevSlide = (e) => {
        if (
            !document
                .querySelector(
                    `.${classRod} .oKrasnoyarsk_menu_switch_item.left`
                )
                .classList.contains("inactive")
        ) {
            refSlide?.current?.slidePrev(e);
        }
    };

    return (
        <div className="oKrasnoyarsk_menu_switch  flex">
            <div
                onClick={(e) => PrevSlide(e)}
                className="oKrasnoyarsk_menu_switch_item inactive left flex "
            >
                <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0.469669 6.53033C0.176776 6.23744 0.176776 5.76256 0.469669 5.46967L5.24264 0.696699C5.53553 0.403806 6.01041 0.403806 6.3033 0.696699C6.59619 0.989592 6.59619 1.46447 6.3033 1.75736L2.06066 6L6.3033 10.2426C6.59619 10.5355 6.59619 11.0104 6.3033 11.3033C6.01041 11.5962 5.53553 11.5962 5.24264 11.3033L0.469669 6.53033ZM16 6.75H0.999999V5.25H16V6.75Z" />
                </svg>
            </div>
            <div
                onClick={(e) => NextSlide(e)}
                className="oKrasnoyarsk_menu_switch_item right flex"
            >
                <svg
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L10.7574 0.696699C10.4645 0.403806 9.98959 0.403806 9.6967 0.696699C9.40381 0.989593 9.40381 1.46447 9.6967 1.75736L13.9393 6L9.6967 10.2426C9.40381 10.5355 9.40381 11.0104 9.6967 11.3033C9.98959 11.5962 10.4645 11.5962 10.7574 11.3033L15.5303 6.53033ZM0 6.75H15V5.25H0V6.75Z" />
                </svg>
            </div>
        </div>
    );
}
