import { useEffect, useState } from "react";

export default function Header(params) {
    const [headStat, setheadStat] = useState(false);
    const [menu, setmenu] = useState(false);
    const [burger, setburger] = useState(false);

    useEffect(() => {
        window.onscroll = () => {
            if (window.scrollY > 20) {
                document
                    .querySelector(".header")
                    .classList.add("active");
            }
            if (window.scrollY < 20) {
                document
                    .querySelector(".header")
                    .classList.remove("active");
            }
        };
    }, [window.scrollY]);

    useEffect(() => {
        if (menu) {
            document
                .querySelector("body")
                .classList.add("fixed");
        } else {
            document
                .querySelector("body")
                .classList.remove("fixed");
        }
    }, [menu]);
    window.onwheel = (e) => {
        if (e.deltaY < 0) {
            setheadStat(false);
            setTimeout(() => {
                if (window.scrollY > 20) {
                    setheadStat(true);
                }
            }, 2500);
        } else {
            setheadStat(true);
        }
    };
    document.addEventListener(
        "touchstart",
        handleTouchStart,
        false
    );
    document.addEventListener(
        "touchmove",
        handleTouchMove,
        false
    );

    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
        return (
            evt.touches || // browser API
            evt.originalEvent.touches
        ); // jQuery
    }

    function handleTouchStart(evt) {
        const firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
    }

    function handleTouchMove(evt) {
        if (!xDown || !yDown) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if (!(Math.abs(xDiff) > Math.abs(yDiff))) {
            /*most significant*/
            if (yDiff > 0) {
                setheadStat(true);
            } else {
                setheadStat(false);
                setTimeout(() => {
                    if (window.scrollY > 20) {
                        setheadStat(true);
                    }
                }, 2500);
            }
        }
        xDown = null;
        yDown = null;
    }
    return (
        <header
            className={headStat ? "header burg" : "header"}
        >
            <div className="container flex">
                <a href="/" className="header_logoBox">
                    <img
                    className="wite"
                        src={
                            process.env.PUBLIC_URL +
                            "img/logoWite.png"
                        }
                        alt="Туристический информационный центр"
                    />
                    <img
                    className="dark"
                        src={
                            process.env.PUBLIC_URL +
                            "img/logo.png"
                        }
                        alt="Туристический информационный центр"
                    />
                </a>
                <div
                    onClick={() => setburger(!burger)}
                    className={
                        burger
                            ? "menu btn1 open"
                            : "menu btn1 "
                    }
                    data-menu="1"
                >
                    <div className="icon-left"></div>
                    <div className="icon-right"></div>
                </div>
                <nav className={burger? "header_navigate flex mob":"header_navigate flex "}>
                    <a
                    onClick={() => setburger(false)}
                        href="#oKrasnoyarsk"
                        className="header_link t16"
                    >
                        Что посмотреть
                    </a>
                    
                    <a href="#ekskurs" onClick={() => setburger(false)} className="header_link t16">
                        
                        Где погулять
                    </a>
                    <a
                    onClick={() => setburger(false)}
                        href="#restor"
                        className="header_link t16"
                    >
                        Где поесть
                    </a>
                    <a href="#otel"
                    onClick={() => setburger(false)} className="header_link t16">
                        Где остановиться
                    </a>
                </nav>
            </div>
        </header>
    );
}
