import { useState } from "react";
import Ekskurs from "./block/ekskurs";
import Footer from "./block/footer";
import Header from "./block/header";
import Hellow from "./block/hello";
import Info from "./block/info";
import OKrasnoyarsk from "./block/oKrasnoyarsk";
import Otel from "./block/otel";
import PopupInfo from "./block/popupInfo";
import Test from "./block/test";
import TopRestor from "./block/topRestor";
import MapBloc from "./block/map";
import Otziv from "./block/otziv";

export default function Main(params) {
    const [active, setActive] = useState(false);
    const [data, setData] = useState(null);
    const [istMap, setIstMap] = useState(null);
    const [load, setLoad ]= useState(false);
    window.addEventListener("load",()=>{
        setLoad(true)
    })
    return (
        <>
            <Header />
            <main className="main">
                <Hellow/>
                <OKrasnoyarsk setActive={setActive} setData={setData}/>
                <Test setIstMap={setIstMap}/>
                <Ekskurs setActive={setActive} setData={setData} />
                <TopRestor setIstMap={setIstMap}/>
                {load && 
                <MapBloc istMap={istMap} setIstMap={setIstMap}/>
                }
                <Otel istMap={istMap} setIstMap={setIstMap}/>
                <Otziv/>
                <Info/>
                <PopupInfo  active={active} data={data} setIstMap={setIstMap} setActive={setActive}/>
            </main>
            <Footer/>
        </>
    );
}
