import { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import Switch from "../modules/swich";
import useDataFetch from "../data/ajsx";
let responsive = {
    0: { items: 1, itemsFit: "containt" },
    320: { items: 2, itemsFit: "containt" },
    640: { items: 3, itemsFit: "containt" },
  };
  if (window.innerWidth <=750) {
    responsive = {
      0: { items: 1, itemsFit: "containt" },
    };
  }
export default function Otel(params) {
    const [isLoaded, error, defolt] = useDataFetch({url:"/api/?hotels=all"})

    const [items, setItems] = useState([]);
    const otel = useRef(null);
    useEffect(() => {
        setItems(defolt);
    }, [defolt]);


    function FilterMass(e) {
        let arrey = [];
        let widKuh = "";

        document
            .querySelectorAll("input[name=otel_type]")
            .forEach((element) => {
                if (element.checked) {
                    widKuh = element.value;
                }
            });

        e.forEach((element) => {
                if (element.type_id.id == widKuh) {
                    arrey.push(element);
                }
        });
        if (arrey.length == 0) {
            arrey = defolt;
        }

        console.log(arrey);
        if (arrey.length != 0) {
            setItems(arrey);
        } else {
            setItems(defolt);
        }
    }

    function moni(e) {
        if (e.item > 0) {
            document
                .querySelectorAll(
                    ".oKrasnoyarsk_menu_switch_item"
                )[6]
                .classList.remove("inactive");
        } else {
            document
                .querySelectorAll(
                    ".oKrasnoyarsk_menu_switch_item"
                )[6]
                .classList.add("inactive");
        }
        if (
            e.item >=
            document.querySelectorAll(".otel_slide_item")
                .length -
                3
        ) {
            document
                .querySelectorAll(
                    ".oKrasnoyarsk_menu_switch_item"
                )[7]
                .classList.add("inactive");
        } else {
            document
                .querySelectorAll(
                    ".oKrasnoyarsk_menu_switch_item"
                )[7]
                .classList.remove("inactive");
        }
    }

    
    return (
        <section className="otel" id="otel">
            <div className="container">
                <h2 className="h2">Где остановиться</h2>
                <div className="otel_filter flex">
                    <div className="otel_filter_type flex">
                        <label
                            className="t16"
                            htmlFor="otel_type0"
                        >
                            <input
                                type="radio"
                                defaultValue={""}
                                defaultChecked
                                onClick={() =>
                                    FilterMass(defolt)
                                }
                                name="otel_type"
                                id="otel_type0"
                            />
                            Все
                        </label>
                        <label
                            className="t16"
                            htmlFor="otel_type1"
                        >
                            <input
                                type="radio"
                                defaultValue={5}
                                onClick={() =>
                                    FilterMass(defolt)
                                }
                                name="otel_type"
                                id="otel_type1"
                            />
                            Гостиницы
                        </label>
                        <label
                            className="t16"
                            htmlFor="otel_type2"
                        >
                            <input
                                type="radio"
                                name="otel_type"
                                onClick={() =>
                                    FilterMass(defolt)
                                }
                                defaultValue={6}
                                id="otel_type2"
                            />
                            Отели
                        </label>
                        <label
                            className="t16"
                            htmlFor="otel_type3"
                        >
                            <input
                                type="radio"
                                name="otel_type"
                                onClick={() =>
                                    FilterMass(defolt)
                                }
                                defaultValue={7}
                                id="otel_type3"
                            />
                            Турбазы
                        </label>
                        <label
                            className="t16"
                            htmlFor="otel_type4"
                        >
                            <input
                                type="radio"
                                name="otel_type"
                                onClick={() =>
                                    FilterMass(defolt)
                                }
                                defaultValue={8}
                                id="otel_type4"
                            />
                            Глэмпинг
                        </label>
                    </div>
                    <Switch refSlide={otel} classRod={'otel'}/>

                </div>

                <div className="otel_slide">
                    {error ? (
                        <p>Ошибка</p>
                    ) : !isLoaded ? (
                        <p>Загрузка</p>
                    ) : (
                        <>
                            <AliceCarousel
                                key="otel"
                                mouseTracking
                                disableDotsControls
                                disableButtonsControls
                                responsive={responsive}
                                ref={otel}
                                onSlideChanged={moni}
                            >
                                {items.map((e, i) => (
                                    <OtelItem
                                        key={i}
                                        type_id={e.type_id}
                                        address={e.address}
                                        setIstMap={
                                            params.setIstMap
                                        }
                                        coordinates={
                                            e.coordinates
                                        }
                                        type_place={
                                            e.type_place
                                        }
                                        description={
                                            e.description
                                        }
                                        chek={
                                            e.average_cheque
                                        }
                                        name={e.name}
                                        img={e.image}
                                        responsive={responsive}
                                    />
                                ))}
                            </AliceCarousel>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
}

function OtelItem(params) {
    const [first, setfirst] = useState(false);
    let a = params.description;
    let w = {
        coordinates: params.coordinates,
        img: params.img,
        name: params.name,
        type:'hotel',
        address:params.address
    };
    return (
        <div className="otel_slide_item">
            <div
                onClick={() => setfirst(true)}
                style={{
                    background: `url(${params.img})`,
                }}
                className="otel_slide_item_img"
            >
                <div className="otel_slide_item_img_type t16">
                    <span> {params.type_id.name}</span>
                </div>
            </div>
            <div className="otel_slide_item_info">
                <p className="otel_slide_item_info_name t20">
                    {params.name}
                </p>
                <a
                    href="#mapBlock"
                    onClick={() => {
                        params.setIstMap(w);
                        setfirst(false);
                    }}
                    className="otel_slide_item_info_lab t16"
                >
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/img/restor/icon/local.png"
                        }
                        alt="Адрес заведения"
                    />
                    <span>{params.address}</span>
                </a>
            </div>
            <div
                className={
                    first
                        ? "otel_slide_item_popup active"
                        : "otel_slide_item_popup"
                }
            >
                <h3 className="t20">{params.name}</h3>
                <a
                    href="#mapBlock"
                    onClick={() => {
                        params.setIstMap(w);
                        setfirst(false);
                    }}
                    className="otel_slide_item_info_lab t16"
                >
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/img/restor/icon/local.png"
                        }
                        alt="Адрес заведения"
                    />
                    <span>{params.address}</span>
                </a>
                <p className="otel_slide_item_popup_text">
                    {a.length > 350
                        ? (a = a.substr(0, 350) + "...")
                        : a}
                </p>
                <button
                    onClick={() => setfirst(false)}
                    className="otel_slide_item_popup_but flex"
                >
                    <svg
                        width="25"
                        height="12"
                        viewBox="0 0 25 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M24 5.25C24.4142 5.25 24.75 5.58579 24.75 6C24.75 6.41421 24.4142 6.75 24 6.75V5.25ZM0.46967 6.53033C0.176777 6.23744 0.176777 5.76256 0.46967 5.46967L5.24264 0.696699C5.53553 0.403806 6.01041 0.403806 6.3033 0.696699C6.59619 0.989592 6.59619 1.46447 6.3033 1.75736L2.06066 6L6.3033 10.2426C6.59619 10.5355 6.59619 11.0104 6.3033 11.3033C6.01041 11.5962 5.53553 11.5962 5.24264 11.3033L0.46967 6.53033ZM24 6.75L1 6.75V5.25L24 5.25V6.75Z"
                            fill="#00314D"
                        />
                    </svg>
                    Назад
                </button>
            </div>
        </div>
    );
}
