import { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import Switch from "../modules/swich";
import useDataFetch from "../data/ajsx";
let responsive = {
    0: { items: 1, itemsFit: "containt" },
    320: { items: 2, itemsFit: "containt" },
    640: { items: 3, itemsFit: "containt" },
    960: { items: 4, itemsFit: "containt" },
    1280: { items: 5, itemsFit: "containt" },
  };
  if (window.innerWidth <=750) {
    responsive = {
      0: { items: 1, itemsFit: "containt" },
    };
  }

export default function Ekskurs(params) {
    const [isLoaded, error, defolt] = useDataFetch({url:"/api/?degust=all"})
    const [items, setItems] = useState([]);
    const ekskurs = useRef(null);
  
    useEffect(() => {
        setItems(defolt);
    }, [defolt]);

    function moni(e) {
        if (e.item > 0) {
            document
                .querySelector(
                    ".ekskurs .oKrasnoyarsk_menu_switch_item.right"
                )
                .classList.remove("inactive");
        } else {
            document
                .querySelector(
                    ".ekskurs .oKrasnoyarsk_menu_switch_item.right"
                )
                .classList.add("inactive");
        }
        if (
            e.item >=
            document.querySelector(
                ".ekskurs_slider_item"
            ).length -
                3
        ) {
            document
                .querySelector(
                    ".ekskurs .oKrasnoyarsk_menu_switch_item.left"
                )
                .classList.add("inactive");
        } else {
            document
                .querySelector(
                    ".ekskurs .oKrasnoyarsk_menu_switch_item.left"
                )
                .classList.remove("inactive");
        }
    }
    function FilterMass(e) {
        let arrey = [];
        let widKuh = "";

        document
            .querySelectorAll("input[name=ekskurs]")
            .forEach((element) => {
                if (element.checked) {
                    widKuh = element.value;
                }
            });

        e.forEach((element) => {
            if (element.category == widKuh) {
                arrey.push(element);
            }
        });
        if (arrey.length == 0) {
            arrey = defolt;
        }

        console.log(arrey);
        if (arrey.length != 0) {
            setItems(arrey);
        } else {
            setItems(defolt);
        }
    }
    return (
        <section className="ekskurs" id="ekskurs">
            <div className="container ">
                <h2 className="h2">Интересные места</h2>
                <div className="ekskurs_hed flex">
                    <div className="ekskurs_hed_filter flex">
                        <label
                            htmlFor="ekskurs_0"
                            className="t16"
                        >
                            <input
                                type="radio"
                                name="ekskurs"
                                id="ekskurs_0"
                                defaultChecked
                                defaultValue={"0"}
                                onClick={() =>
                                    FilterMass(defolt)
                                }
                            />
                            Все
                        </label>
                        {/* <label
                            htmlFor="ekskurs_1"
                            className="t16"
                        >
                            <input
                                type="radio"
                                name="ekskurs"
                                id="ekskurs_1"
                                defaultValue={"музеи и галереи"}
                                onClick={() =>
                                    FilterMass(defolt)
                                }
                            />
                            Музеи
                        </label> */}
                        <label
                            htmlFor="ekskurs_2"
                            className="t16"
                        >
                            <input
                                type="radio"
                                name="ekskurs"
                                id="ekskurs_2"
                                defaultValue={"историческое"}
                                onClick={() =>
                                    FilterMass(defolt)
                                }
                            />
                            Историческое

                        </label>
                        <label
                            htmlFor="ekskurs_3"
                            className="t16"
                        >
                            <input
                                type="radio"
                                name="ekskurs"
                                id="ekskurs_3"
                                defaultValue={"природное"}
                                onClick={() =>
                                    FilterMass(defolt)
                                }
                            />
                            Природное
                        </label>
                    </div>
                    <Switch refSlide={ekskurs} classRod={'ekskurs'}/>

                   
                </div>

                <div className="ekskurs_slider">
                    {error ? (
                        <p>Ошибка</p>
                    ) : !isLoaded ? (
                        <p>Загрузка</p>
                    ) : (
                        <AliceCarousel
                            key="carousel"
                            mouseTracking
                            disableDotsControls
                            disableButtonsControls
                            items={items}
                            ref={ekskurs}
                            responsive={responsive}
                            onSlideChanged={moni}
                        >
                            {items.map((e, i) => (
                                <EkskursItem
                                    setActive={
                                        params.setActive
                                    }
                                    data={e}
                                    setData={params.setData}
                                    address={e.address}
                                    key={i}
                                    id={e.id}
                                    img={e.image}
                                />
                            ))}
                        </AliceCarousel>
                    )}
                </div>
            </div>
        </section>
    );
}

function EkskursItem(params) {
    let a =params.data.name;

    useEffect(() => {
     
    }, [a])
    
    function pop() {
        params.setActive(true);
        params.setData({
            name: params.data.name,
            img: params.data.image,
            content: params.data.description,
            local: params.data.address,
            coordinates: params.data.coordinates,
        });
    }
    if ( a.length > 30) {
        a = a.substr(0, 30) + "..."
    }
  
    return (
        <div
        
            onClick={() => pop()}
            style={{
                background: `url(${params.data.image})`,
            }}
            className="ekskurs_slider_item flex"
        >
            {/* <div  className="ekskurs_slider_item_img">

            </div> */}
            <h3 data-text={a} data-hover={params.data.name} className="t18 flex"></h3>
        </div>
    );
}
