


export default function Footer(params) {
    

    return (
        <footer className="footer">
            <div className="container flex">
                    <a href="/" className="footer_img">
                    <img src={process.env.PUBLIC_URL +"img/logo.png"} alt="Туристический информационный центр" />

                    </a>

                   <div className="footer_linkBox flex">
                   <nav className="footer_nav flex">
                   <a
                        href="#oKrasnoyarsk"
                        className="header_link t16"
                    >
                        Что посмотреть
                    </a>
                    <a href="#ekskurs" className="header_link t16">
                        Где погулять
                    </a>
                    <a
                        href="#restor"
                        className="header_link t16"
                    >
                        Где поесть
                    </a>
                    <a href="#otel" className="header_link t16">
                        Где остановиться
                    </a>
                    </nav>
                    <nav className="footer_nav flex">
                    <a href="https://vk.com/visitsiberia_info" className="footer_link_massege flex"><img src={process.env.PUBLIC_URL+"img/icons/wk.png"} alt="" /></a>
                    <a href="https://t.me/kolesimpokrau" className="footer_link_massege flex"><img src={process.env.PUBLIC_URL+"img/icons/tg.png"} alt="" /></a>

                    </nav>
                   </div>
            </div>
        </footer>
    )
}