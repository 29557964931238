import { useEffect } from "react";

export default function PopupInfo({ active, setActive, data, setIstMap }) {

  useEffect(() => {
    if (active) {
      document.querySelector("body").classList.add("poapActive")
    }else{
      document.querySelector("body").classList.remove("poapActive")

    }
  }, [active])
  
  return (
    <div
      onClick={() => setActive(false)}
      className={active ? "popupInfo flex active" : "popupInfo flex"}
    >
      {data && (
        <div onClick={(e) => e.stopPropagation()} className="popupInfo_content">
          
            <div
              style={{ background: `url(${data.img})` }}
              className="popupInfo_content_img"
            ></div>

          <h3 className="t20">{data.name}</h3>
          {data.local && (
            <a href="#mapBlock" onClick={()=>{setIstMap({img:`/img/ekskurs/${data.img}`,coordinates:data.coordinates,name:data.name,type:"museum",address:data.local});setActive(false)}} className="otel_slide_item_info_lab t16">
              <img src="/img/restor/icon/local.png" alt="Адрес заведения" />
              <span>{data.local}</span>
            </a>
          )}
          <p
            dangerouslySetInnerHTML={{ __html: data.content }}
            className="otel_slide_item_popup_text"
          ></p>
          <button onClick={()=>setActive(false)} className="otel_slide_item_popup_but flex">Закрыть</button>
        </div>
      )}
    </div>
  );
}
