import { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const responsive = {
    0: { items: 1, itemsFit: "containt" },
    50: { items: 2, itemsFit: "containt" },
};
export default function Test(params) {
    const [step, setstep] = useState(1);
    const [progress, setProgress] = useState([]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [otvet, setOtvet] = useState(null);
    const test = useRef(null);
    const testImg = useRef(null);
    const [index, setindex] = useState(false)
    useEffect(() => {
     
    }, [otvet])
    
    let items = [
        <div className="test_content_item flex">
            <h2 className="h2">
                Подбери свой туристический сет
            </h2>
            <p className="test_content_item_text t20">
                Создайте свой собственный маршрут и
                наслаждайтесь путешествием в любое удобное
                для вас время
            </p>
            <button
                onClick={(e) => testGo(e)}
                className="test_content_item_butt t16 flex"
            >
                Создать{" "}
                <svg
                    width="25"
                    height="12"
                    viewBox="0 0 25 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M24.5303 6.54486C24.8232 6.25196 24.8232 5.77709 24.5303 5.4842L19.7574 0.711226C19.4645 0.418332 18.9896 0.418332 18.6967 0.711226C18.4038 1.00412 18.4038 1.47899 18.6967 1.77189L22.9393 6.01453L18.6967 10.2572C18.4038 10.5501 18.4038 11.0249 18.6967 11.3178C18.9896 11.6107 19.4645 11.6107 19.7574 11.3178L24.5303 6.54486ZM0 6.76453H24V5.26453H0V6.76453Z" />
                </svg>
            </button>
        </div>,
        <div className="test_content_item flex">
            <p className="h2">
            Вы уже бывали в Красноярском крае или первый раз?  
            </p>
            <div className="test_content_item_boxOpr flex">
                <label htmlFor="step_1_1">
                    <div></div>
                    <input
                        type="radio"
                        name="step_1"
                        defaultValue={"Первый раз"}
                        id="step_1_1"
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Первый раз
                    </span>
                </label>
                <label htmlFor="step_1_2">
                    <div></div>
                    <input
                        type="radio"
                        name="step_1"
                        defaultValue={"Был уже несколько раз"}
                        id="step_1_2"
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Был уже несколько раз
                    </span>
                </label>

                <label htmlFor="step_1_3">
                    <div></div>
                    <input
                        type="radio"
                        name="step_1"
                        defaultValue={"Езжу сюда регулярно"}
                        id="step_1_3"
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Езжу сюда регулярно
                    </span>
                </label>
            
            </div>
            <button
                onClick={(e) => beacGo(e)}
                className="test_content_item_butt t16 flex "
            >
                <svg
                    width="25"
                    height="13"
                    viewBox="0 0 25 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0.469669 7.03033C0.176777 6.73744 0.176777 6.26256 0.469669 5.96967L5.24264 1.1967C5.53553 0.903806 6.01041 0.903806 6.3033 1.1967C6.59619 1.48959 6.59619 1.96447 6.3033 2.25736L2.06066 6.5L6.3033 10.7426C6.59619 11.0355 6.59619 11.5104 6.3033 11.8033C6.01041 12.0962 5.53553 12.0962 5.24264 11.8033L0.469669 7.03033ZM25 7.25H1V5.75H25V7.25Z" />
                </svg>
                Назад{" "}
            </button>
        </div>,
        <div className="test_content_item flex">
            <p className="h2">
                Чего вы ждете от путешествия в Красноярский
                край?
            </p>
            <div className="test_content_item_boxOpr flex">
                <label htmlFor="step_2_1">
                    <div></div>
                    <input
                        type="radio"
                        name="step_2"
                        id="step_2_1"
                        defaultValue={2}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Релакс и близость с природой 
                    </span>
                </label>
                <label htmlFor="step_2_2">
                    <div></div>
                    <input
                        type="radio"
                        name="step_2"
                        id="step_2_2"
                        defaultValue={5}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Уникальный исторический опыт 
                    </span>
                </label>
                <label htmlFor="step_2_3">
                    <div></div>
                    <input
                        type="radio"
                        name="step_2"
                        id="step_2_3"
                        defaultValue={1}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Необычные вкусы сибирской кухни 
                    </span>
                </label>
                <label htmlFor="step_2_4">
                    <div></div>
                    <input
                        type="radio"
                        name="step_2"
                        id="step_2_4"
                        defaultValue={3}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Красивые фотографии рек и гор 
                    </span>
                </label>
            </div>
            <button
                onClick={(e) => beacGo(e)}
                className="test_content_item_butt t16 flex "
            >
                <svg
                    width="25"
                    height="13"
                    viewBox="0 0 25 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0.469669 7.03033C0.176777 6.73744 0.176777 6.26256 0.469669 5.96967L5.24264 1.1967C5.53553 0.903806 6.01041 0.903806 6.3033 1.1967C6.59619 1.48959 6.59619 1.96447 6.3033 2.25736L2.06066 6.5L6.3033 10.7426C6.59619 11.0355 6.59619 11.5104 6.3033 11.8033C6.01041 12.0962 5.53553 12.0962 5.24264 11.8033L0.469669 7.03033ZM25 7.25H1V5.75H25V7.25Z" />
                </svg>
                Назад{" "}
            </button>
        </div>,
        <div className="test_content_item flex">
            <p className="h2">
                Что бы вы хотели посетить в первую очередь в
                Красноярском крае?
            </p>
            <div className="test_content_item_boxOpr flex">
                <label htmlFor="step_3_1">
                    <div></div>
                    <input
                        type="radio"
                        name="step_3"
                        id="step_3_1"
                        defaultValue={2}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Хочу пройтись по паркам, набережным
                    </span>
                </label>
                <label htmlFor="step_3_2">
                    <div></div>
                    <input
                        type="radio"
                        name="step_3"
                        id="step_3_2"
                        defaultValue={6}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Хочу покорить вершину «Красноярских столбов» 
                    </span>
                </label>
                <label htmlFor="step_3_3">
                    <div></div>
                    <input
                        type="radio"
                        name="step_3"
                        id="step_3_3"
                        defaultValue={4}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Хочу погонять на гоночной трассе «Красное кольцо» 
                    </span>
                </label>
                <label htmlFor="step_3_4">
                    <div></div>
                    <input
                        type="radio"
                        name="step_3"
                        id="step_3_4"
                        defaultValue={5}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Хочу заглянуть в музеи, узнать про историю города 
                    </span>
                </label>
                <label htmlFor="step_3_5">
                    <div></div>
                    <input
                        type="radio"
                        name="step_3"
                        id="step_3_5"
                        defaultValue={1}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Хочу попробовать местную кухню 
                    </span>
                </label>
            </div>
            <button
                onClick={(e) => beacGo(e)}
                className="test_content_item_butt t16 flex "
            >
                {" "}
                <svg
                    width="25"
                    height="13"
                    viewBox="0 0 25 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0.469669 7.03033C0.176777 6.73744 0.176777 6.26256 0.469669 5.96967L5.24264 1.1967C5.53553 0.903806 6.01041 0.903806 6.3033 1.1967C6.59619 1.48959 6.59619 1.96447 6.3033 2.25736L2.06066 6.5L6.3033 10.7426C6.59619 11.0355 6.59619 11.5104 6.3033 11.8033C6.01041 12.0962 5.53553 12.0962 5.24264 11.8033L0.469669 7.03033ZM25 7.25H1V5.75H25V7.25Z" />
                </svg>
                Назад{" "}
            </button>
        </div>,
        <div className="test_content_item flex">
            <p className="h2">
                Какие ваши гастрономические предпочтения во
                время путешествий?
            </p>
            <div className="test_content_item_boxOpr flex">
                <label htmlFor="step_4_1">
                    <div></div>
                    <input
                        type="radio"
                        name="step_4"
                        id="step_4_1"
                        defaultValue={3}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Чем красивее блюдо, тем лучше! 
                    </span>
                </label>
                <label htmlFor="step_4_2">
                    <div></div>
                    <input
                        type="radio"
                        name="step_4"
                        id="step_4_2"
                        defaultValue={5}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Люблю пробовать кухню местных народов 
                    </span>
                </label>
                <label htmlFor="step_4_3">
                    <div></div>
                    <input
                        type="radio"
                        name="step_4"
                        id="step_4_3"
                        defaultValue={4}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Готов отведать самые странные блюда
                    </span>
                </label>

                <label htmlFor="step_4_4">
                    <div></div>
                    <input
                        type="radio"
                        name="step_4"
                        id="step_4_4"
                        defaultValue={2}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Что-то легкое, не хочу отвлекаться от путешествия
                    </span>
                </label>
                <label htmlFor="step_4_5">
                    <div></div>
                    <input
                        type="radio"
                        name="step_4"
                        id="step_4_5"
                        defaultValue={1}
                        onClick={(e) => testGo(e)}
                    />
                    <span className="test_content_item_text t20">
                    Люблю пробовать необычные авторские блюда 
                    </span>
                </label>
            </div>
            <button
                onClick={(e) => beacGo(e)}
                className="test_content_item_butt t16 flex "
            >
                {" "}
                <svg
                    width="25"
                    height="13"
                    viewBox="0 0 25 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0.469669 7.03033C0.176777 6.73744 0.176777 6.26256 0.469669 5.96967L5.24264 1.1967C5.53553 0.903806 6.01041 0.903806 6.3033 1.1967C6.59619 1.48959 6.59619 1.96447 6.3033 2.25736L2.06066 6.5L6.3033 10.7426C6.59619 11.0355 6.59619 11.5104 6.3033 11.8033C6.01041 12.0962 5.53553 12.0962 5.24264 11.8033L0.469669 7.03033ZM25 7.25H1V5.75H25V7.25Z" />
                </svg>
                Назад{" "}
            </button>
        </div>,
        <div className="test_content_item flex">
            <p className="h2">
                Что бы вы привезли из путешествия?
            </p>
            <div className="test_content_item_boxOpr flex">
                <label htmlFor="step_5_1">
                    <div></div>
                    <input
                        type="radio"
                        name="step_5"
                        id="step_5_1"
                        defaultValue={6}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Заберу с собой камушек с берега Енисея 
                    </span>
                </label>
                <label htmlFor="step_5_2">
                    <div></div>
                    <input
                        type="radio"
                        name="step_5"
                        id="step_5_2"
                        defaultValue={4}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Максимум эмоций! 
                    </span>
                </label>
                <label htmlFor="step_5_3">
                    <div></div>
                    <input
                        type="radio"
                        name="step_5"
                        id="step_5_3"
                        defaultValue={5}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Открытку с исторической достопримечательностью 
                    </span>
                </label>

                <label htmlFor="step_5_4">
                    <div></div>
                    <input
                        type="radio"
                        name="step_5"
                        id="step_5_4"
                        defaultValue={1}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Местные вкусности: варенье с шишек, иван-чай 
                    </span>
                </label>
            </div>
            <button
                onClick={(e) => beacGo(e)}
                className="test_content_item_butt t16 flex "
            >
                <svg
                    width="25"
                    height="13"
                    viewBox="0 0 25 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0.469669 7.03033C0.176777 6.73744 0.176777 6.26256 0.469669 5.96967L5.24264 1.1967C5.53553 0.903806 6.01041 0.903806 6.3033 1.1967C6.59619 1.48959 6.59619 1.96447 6.3033 2.25736L2.06066 6.5L6.3033 10.7426C6.59619 11.0355 6.59619 11.5104 6.3033 11.8033C6.01041 12.0962 5.53553 12.0962 5.24264 11.8033L0.469669 7.03033ZM25 7.25H1V5.75H25V7.25Z" />
                </svg>
                Назад{" "}
            </button>
        </div>,
        <div className="test_content_item flex">
            <p className="h2">
                Что будет запечатлено на ваших фотографиях
                из путешествия по Красноярскому краю?{" "}
            </p>
            <div className="test_content_item_boxOpr flex">
                <label htmlFor="step_6_1">
                    <div></div>
                    <input
                        type="radio"
                        name="step_6"
                        id="step_6_1"
                        defaultValue={2}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Бескрайняя тайга и Енисей
                    </span>
                </label>
                <label htmlFor="step_6_2">
                    <div></div>
                    <input
                        type="radio"
                        name="step_6"
                        id="step_6_2"
                        defaultValue={6}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Я на вершине «Красноярских столбов!»
                    </span>
                </label>
                <label htmlFor="step_6_3">
                    <div></div>
                    <input
                        type="radio"
                        name="step_6"
                        id="step_6_3"
                        defaultValue={4}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Думаю, что от активностей времени на фото не будет 
                    </span>
                </label>

                <label htmlFor="step_6_4">
                    <div></div>
                    <input
                        type="radio"
                        name="step_6"
                        id="step_6_4"
                        onClick={(e) => testGo(e)}
                        defaultValue={5}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Красивые здания, памятники и экспонаты музеев 
                    </span>
                </label>
                <label htmlFor="step_6_5">
                    <div></div>
                    <input
                        type="radio"
                        name="step_6"
                        id="step_6_5"
                        defaultValue={1}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Красивые здания, памятники и экспонаты музеев 
                    </span>
                </label>
                <label htmlFor="step_6_6">
                    <div></div>
                    <input
                        type="radio"
                        name="step_6"
                        id="step_6_6"
                        defaultValue={3}
                        onClick={(e) => testGo(e)}
                    />{" "}
                    <span className="test_content_item_text t20">
                    Всё! Успею сфотографировать и реку, и город, и еду 
                    </span>
                </label>
            </div>
            <button
                onClick={(e) => beacGo(e)}
                className="test_content_item_butt t16 flex "
            >
                <svg
                    width="25"
                    height="13"
                    viewBox="0 0 25 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M0.469669 7.03033C0.176777 6.73744 0.176777 6.26256 0.469669 5.96967L5.24264 1.1967C5.53553 0.903806 6.01041 0.903806 6.3033 1.1967C6.59619 1.48959 6.59619 1.96447 6.3033 2.25736L2.06066 6.5L6.3033 10.7426C6.59619 11.0355 6.59619 11.5104 6.3033 11.8033C6.01041 12.0962 5.53553 12.0962 5.24264 11.8033L0.469669 7.03033ZM25 7.25H1V5.75H25V7.25Z" />
                </svg>
                Назад
            </button>
        </div>,
        <div className="test_content_item flex otvets">

            <p className="h2">Вы прошли тест!</p>
            <div className="test_content_item_text t20"></div>
            <div className="test_content_item_butBox flex">
                <button
                    onClick={(e) => beacGo(e)}
                    className="test_content_item_butt t16 flex "
                >
                    <svg
                        width="25"
                        height="13"
                        viewBox="0 0 25 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M0.469669 7.03033C0.176777 6.73744 0.176777 6.26256 0.469669 5.96967L5.24264 1.1967C5.53553 0.903806 6.01041 0.903806 6.3033 1.1967C6.59619 1.48959 6.59619 1.96447 6.3033 2.25736L2.06066 6.5L6.3033 10.7426C6.59619 11.0355 6.59619 11.5104 6.3033 11.8033C6.01041 12.0962 5.53553 12.0962 5.24264 11.8033L0.469669 7.03033ZM25 7.25H1V5.75H25V7.25Z" />
                    </svg>
                    Назад
                </button>
                <button
                    onClick={() => removeTest()}
                    className="test_content_item_butt t16 flex"
                >
                    Пройти снова
                </button>
            </div>
        </div>,
    ];
    let itemsImg = [
        <div
            style={{ background: `url(/img/test/7.jpg)` }}
            className="test_img_item"
        ></div>,
        <div
            style={{ background: `url(/img/test/1.jpg)` }}
            className="test_img_item"
        ></div>,
        <div
            style={{ background: `url(/img/test/2.jpg)` }}
            className="test_img_item"
        ></div>,
        <div
            style={{ background: `url(/img/test/3.jpg)` }}
            className="test_img_item"
        ></div>,
        <div
            style={{ background: `url(/img/test/4.jpg)` }}
            className="test_img_item"
        ></div>,
        <div
            style={{ background: `url(/img/test/5.jpg)` }}
            className="test_img_item"
        ></div>,
        <div
            style={{ background: `url(/img/test/6.jpg)` }}
            className="test_img_item"
        ></div>,
        <div
            style={{ background: `url(/img/test/8.jpg)` }}
            className="test_img_item otvets"
        >
            <h3 className="test_img_item_title">
                Рекомендуем для вас
            </h3>
            {error ? (
                <p>Ошибка</p>
            ) : !isLoaded ? (
                <p>Загрузка</p>
            ) : (
                <>
            
                    <ul className="test_img_item_content">
                        {otvet.map((e, i) => (
                            <li
                                key={i}
                                className="test_img_item_content_item"
                            >
                                <a
                                    href="#mapBlock"
                                    onClick={() =>
                                        params.setIstMap({
                                            img: `/img/noimg.png`,
                                            coordinates:
                                                e.coordinates,
                                            name: e.name,
                                            type: "museum",
                                            address:
                                                e.address,
                                        })
                                    }
                                    className="test_img_item_content_item_mest t18"
                                >
                                    {e.name}
                                </a>
                                <br />
                                <span className="test_img_item_content_item_opis t20">
                                    {e.description}
                                </span>
                            </li>
                        ))}
                        <a href="https://visitsiberia.info/audiogidi-po-krasnoyary.html" className="t16 test_img_item_content_item_link" target="_blank" rel="noopener noreferrer">Узнать больше о Красноярском крае</a>
                    </ul>
                </>
            )}
        </div>,
    ];
    const beacGo = (e) => {
        test?.current?.slidePrev(e);
        setstep(step - 1);
        testImg?.current?.slidePrev(e);
    };
    const removeTest = () => {
        setstep(2);
        test?.current?.slideTo(1);

        testImg?.current?.slideTo(1);
        document.querySelectorAll(".step")[0].click();
        document.querySelector(".-its").innerHTML = "1";
        document
            .querySelectorAll(".test input")
            .forEach((element) => {
                element.checked = false;
            });
        document
            .querySelector(".test_progressBar")
            .classList.remove("noact");
    };
    const testGo = (e) => {
        console.log(step);
        switch (step) {
            case 1:
                test?.current?.slideNext(e);
                document
                    .querySelector(".test_progressBar")
                    .classList.remove("noact");
                document.querySelector(".-its").innerHTML =
                    "1";
                testImg?.current?.slideNext(e);

                setstep(step + 1);

                break;
            case 2:
                test?.current?.slideNext(e);
                document.querySelector(".-its").innerHTML =
                    "2";
                testImg?.current?.slideNext(e);
                progressBar(1);

                setstep(step + 1);

                break;
            case 3:
                test?.current?.slideNext(e);
                testImg?.current?.slideNext(e);

                setstep(step + 1);
                document.querySelector(".-its").innerHTML =
                    "3";
                progressBar(2);

                break;
            case 4:
                test?.current?.slideNext(e);
                testImg?.current?.slideNext(e);
                setstep(step + 1);
                document.querySelector(".-its").innerHTML =
                    "4";
                progressBar(3);

                break;
            case 5:
                test?.current?.slideNext(e);
                testImg?.current?.slideNext(e);

                setstep(step + 1);
                document.querySelector(".-its").innerHTML =
                    "5";
                progressBar(4);

                break;
            case 6:
                test?.current?.slideNext(e);
                testImg?.current?.slideNext(e);

                setstep(step + 1);
                document.querySelector(".-its").innerHTML =
                    "6";
                progressBar(5);

                break;
            case 7:
                test?.current?.slideNext(e);
                testImg?.current?.slideNext(e);
                progressBar(6);
                document
                    .querySelector(".test_progressBar")
                    .classList.add("noact");
                let w = {};
                document
                    .querySelectorAll(
                        ".test_content_item input"
                    )
                    .forEach((element) => {
                        if (element.checked) {
                            w[
                                `${element.getAttribute(
                                    "name"
                                )}`
                            ] = element.value;
                        }
                    });

                w = Object.entries(w).reduce(
                    (acc, [k, v]) => (
                        (acc[v] = acc[v] || []).push(k), acc
                    ),
                    {}
                );
                console.log(w);
                let big = 0;
                let tr = 0;
                for (let i in w) {
                    if (w[i].length > big) {
                        big = w[i].length;
                        tr = i;
                    }
                }
                console.log(tr);
                const ends =
                    document.querySelector(".otvets");
                fetch(
                    `/api/?test=${tr}`
                )
                    .then((res) => res.json())
                    .then(
                        (result) => {
                            console.log(result);
                            setOtvet(
                                result.interesting_places_for_quiz_id
                            );
                            setIsLoaded(true);
                            ends.querySelector(
                                ".h2"
                            ).innerHTML = result.name;
                            ends.querySelector(
                                ".test_content_item_text"
                            ).innerHTML = result.text;
                        },
                        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                        // чтобы не перехватывать исключения из ошибок в самих компонентах.
                        (error) => {
                            console.log(error);
                            setIsLoaded(true);
                            setError(error);
                        }
                    );

                setstep(step + 1);

                break;
            default:
                break;
        }
    };
    document
        .querySelectorAll(".test_content_item input")
        .forEach((element) => {
            element.addEventListener("click", () => {
                buttActive();
            });
        });
    const buttActive = (e) => {
        let a = false;
        document
            .querySelectorAll(
                `input[name=step_${step - 1}]`
            )
            .forEach((element) => {
                if (element.checked) {
                    a = true;
                }
            });
        if (a) {
            document
                .querySelectorAll(".test_content_item_butt")
                [step - 1].classList.remove("onActive");
        }
    };

    let els = document.getElementsByClassName("step");
    let steps = [];
    Array.prototype.forEach.call(els, (e) => {
        steps.push(e);
        e.addEventListener("click", (x) => {
            progressBar(x.target.id);
        });
    });

    function progressBar(stepNum) {
        let p = stepNum * 20;
        document.getElementsByClassName(
            "percent"
        )[0].style.width = `${p}%`;
        steps.forEach((e) => {
            if (e.id === stepNum) {
                e.classList.add("selected");
                e.classList.remove("completed");
            }
            if (e.id < stepNum) {
                e.classList.add("completed");
            }
            if (e.id >= stepNum) {
                e.classList.remove("selected", "completed");
            }
        });
    }
    return (
        <section className="test" id="test">
                {
                     step === 8 &&
            <div onClick={()=>setindex(!index)} className="_butN"></div>

                }
            <div className="container">

                <div
                    className={
                        step === 8 && !index
                            ? "test_content ot"
                            : "test_content"
                    }
                     style={ {  background:step === 8&& index && '#00314d'}}
                >

                    <AliceCarousel
                        key="test"
                        disableDotsControls
                        disableButtonsControls
                        touchTracking={false}
                        touchMoveDefaultEvents={false}
                        ref={test}
                        items={items}
                        onSlideChange={buttActive}
                        autoWidth
                        animationType="fadeout"
                    />
                </div>
                <div className="test_img">
                    <AliceCarousel
                        key="testImg"
                        disableDotsControls
                        disableButtonsControls
                        autoWidth
                        // responsive={responsive}
                        touchTracking={false}
                        touchMoveDefaultEvents={false}
                        items={itemsImg}
                        ref={testImg}
                        animationType="fadeout"
                    />
                </div>
                <div className="test_progressBar flex noact">
                    <div className="test_progressBar_check">
                        <span className="test_progressBar_check -its">
                            1
                        </span>
                        /
                        <span className="test_progressBar_check -all">
                            6
                        </span>
                    </div>
                    <div className="test_progressBar_step">
                        <div className="progress">
                            <div className="percent"></div>
                        </div>
                        <div className="steps">
                            <div
                                className="step"
                                data-widht="0"
                                id="0"
                            ></div>
                            <div
                                className="step"
                                data-widht="0"
                                id="1"
                            ></div>
                            <div
                                className="step"
                                data-widht="0"
                                id="2"
                            ></div>
                            <div
                                className="step"
                                data-widht="0"
                                id="3"
                            ></div>
                            <div
                                className="step"
                                data-widht="0"
                                id="4"
                            ></div>
                            <div
                                className="step"
                                data-widht="0"
                                id="5"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
