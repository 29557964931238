import { useEffect, useState } from "react";
import { URL_API } from "./param";

// пользовательский хук для реальзации и унификации всех запросов к api
function useDataFetch({ url }) {
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [items, setItems] = useState([]);

    async function  ajax({ url }) {
         fetch(`${URL_API}${url}`)
            .then((res) => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    setLoaded(true);
                    setItems(result);

                },

                (error) => {
                    setLoaded(true);
                    setError(error);
                }
            );
    }
    useEffect(() => {
        ajax({ url: url });
    }, []);

    return [loaded, error, items, ajax];
}

export default useDataFetch;
