import { useEffect, useState } from "react";
import Preloader from "../modules/preloader";

export default function Hellow(params) {
    const [lod, setLod] = useState(false);
    useEffect(() => {
      document.querySelector("body").classList.add('fixed')
    }, [])
    
    function Loading(params) {
        console.log("LoadingOk");
      document.querySelector("body").classList.remove('fixed')

        setLod(true);
    }
    return (
        <div className="preloader">
            <div className="page">
                <section
                onLoad={Loading}
                    className={lod ? "hello" : "hello load"}
                >
                    {
                        lod &&
                        <>
                         <img
                        className="hello_fon bg_1"
                        src={
                            process.env.PUBLIC_URL +
                            "/img/hello/bg_1.png"
                        }
                        alt=""
                    />
                   
                     <div className="hello_fon bg_4">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/hello/strel.svg"
                            }
                            alt=""
                        />
                    </div>
                    <div className="hello_content flex">
                        <a
                            className="hello_content_but t16"
                            href="#test"
                        >
                            Начать путешествие
                        </a>
                    </div>
                    <div className="hello_contact flex">
                        <a
                            href="https://vk.com/visitsiberia_info"
                            className="hello_contact_item flex"
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/hello/wk.png"
                                }
                                alt=""
                            />
                        </a>
                        <a
                            href="https://t.me/kolesimpokrau"
                            className="hello_contact_item flex"
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/hello/tg.png"
                                }
                                alt=""
                            />
                        </a>
                        <a
                            href="https://ok.ru/visitsiberia"
                            className="hello_contact_item flex"
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/hello/ok.png"
                                }
                                alt=""
                            />
                        </a>
                    </div>
                        </>
                    }
                   
                    <img
                        className="hello_fon bg_3"
                        
                        src={
                            process.env.PUBLIC_URL +
                            "/img/hello/bg_3.webp"
                        }
                        alt=""
                    />
                     <img
                        className="hello_fon bg_2"
                        src={
                            process.env.PUBLIC_URL +
                            "/img/hello/bg_2.png"
                        }
                        alt=""
                    />
                   
                </section>
                <section className="hello mob">
                   
                    {lod &&
                    
                    <>
                     <img
                        className="hello_fon bg_1"
                        src={
                            process.env.PUBLIC_URL +
                            "/img/hello/bg_1.png"
                        }
                        alt=""
                    />
                    <img
                        className="hello_fon bg_2"
                        src={
                            process.env.PUBLIC_URL +
                            "/img/hello/bg_2m.png"
                        }
                        alt=""
                    />
                        <div className="hello_fon bg_4">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/hello/strelm.svg"
                            }
                            alt=""
                        />
                    </div>
                    <div className="hello_content flex">
                        <a
                            className="hello_content_but t16"
                            href="#test"
                        >
                            Начать путешествие
                        </a>
                    </div>
                    <div className="hello_contact flex">
                        <a
                            href="https://vk.com/visitsiberia_info"
                            className="hello_contact_item flex"
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/hello/wk.png"
                                }
                                alt=""
                            />
                        </a>
                        <a
                            href="https://t.me/kolesimpokrau"
                            className="hello_contact_item flex"
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/hello/tg.png"
                                }
                                alt=""
                            />
                        </a>
                        <a
                            href="https://ok.ru/visitsiberia"
                            className="hello_contact_item flex"
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/hello/ok.png"
                                }
                                alt=""
                            />
                        </a>
                    </div>
                    </>
                    }
                    <img
                        className="hello_fon bg_3"
                        onLoad={Loading}

                        src={
                            process.env.PUBLIC_URL +
                            "/img/hello/bg_3.png"
                        }
                        alt=""
                    />
                
                </section>
            </div>
            <div
                id="splash"
                className={lod ? " active" : ""}
            >
                <div
                    className={
                        lod ? "anim active" : "anim "
                    }
                >
                    <div id="loader">
                        <svg
                            version="1.1"
                            width="60px"
                            height="70px"
                            viewBox="0 0 60 70"
                        >
                            <defs>
                                <filter id="f1" x="0" y="0">
                                    <feGaussianBlur
                                        in="SourceGraphic"
                                        stdDeviation="2"
                                    />
                                </filter>
                            </defs>
                            <g id="airplane">
                                <path
                                    fill="#000"
                                    d="M0.677,20.977l4.355,1.631c0.281,0.104,0.579,0.162,0.88,0.16l9.76-0.004L30.46,41.58c0.27,0.34,0.679,0.545,1.112,0.541
          h1.87c0.992,0,1.676-0.992,1.322-1.918l-6.643-17.439l6.914,0.002l6.038,6.037c0.265,0.266,0.624,0.412,0.999,0.418l1.013-0.004
          c1.004-0.002,1.684-1.012,1.312-1.938l-2.911-7.277l2.912-7.278c0.372-0.928-0.313-1.941-1.313-1.938h1.017
          c-0.375,0-0.732,0.15-0.996,0.414l-6.039,6.039h-6.915l6.646-17.443c0.354-0.926-0.33-1.916-1.321-1.914l-1.87-0.004
          c-0.439,0.004-0.843,0.203-1.112,0.543L15.677,17.24l-9.765-0.002c-0.3,0.002-0.597,0.055-0.879,0.16L0.678,19.03
          C-0.225,19.36-0.228,20.637,0.677,20.977z"
                                    transform="translate(44,0) rotate(90 0 0)"
                                />
                            </g>
                            <g
                                id="shadow"
                                transform="scale(.9)"
                            >
                                <path
                                    fill="#000"
                                    fillOpacity="0.3"
                                    d="M0.677,20.977l4.355,1.631c0.281,0.104,0.579,0.162,0.88,0.16l9.76-0.004L30.46,41.58c0.27,0.34,0.679,0.545,1.112,0.541
      		h1.87c0.992,0,1.676-0.992,1.322-1.918l-6.643-17.439l6.914,0.002l6.038,6.037c0.265,0.266,0.624,0.412,0.999,0.418l1.013-0.004
      		c1.004-0.002,1.684-1.012,1.312-1.938l-2.911-7.277l2.912-7.278c0.372-0.928-0.313-1.941-1.313-1.938h1.017
      		c-0.375,0-0.732,0.15-0.996,0.414l-6.039,6.039h-6.915l6.646-17.443c0.354-0.926-0.33-1.916-1.321-1.914l-1.87-0.004
      		c-0.439,0.004-0.843,0.203-1.112,0.543L15.677,17.24l-9.765-0.002c-0.3,0.002-0.597,0.055-0.879,0.16L0.678,19.03
      		C-0.225,19.36-0.228,20.637,0.677,20.977z"
                                    transform="translate(64,30) rotate(90 0 0)"
                                    filter="url(#f1)"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}
