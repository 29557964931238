import {
    Map,
    Placemark,
    YMaps,
} from "@pbe/react-yandex-maps";

import {
    useEffect,
    useState,
} from "react";
export default function MapBloc(params) {
    const [defoltcor, setDefoltcor] = useState([
        56.0105, 92.8525,
    ]);
    const [map, setmap] = useState(
        <YMaps
            query={{
                apikey: "92e27a44-279d-48bf-be97-d95e84154e5e",
                lang: "ru_RU",
            }}
            className="map_content_map"
        >
            <Map
                height={"500px"}
                width={"100%"}
                state={{
                    center: defoltcor,
                    zoom: 8,
                    controls: [
                        "zoomControl",
                        "fullscreenControl",
                    ],
                }}
                modules={[
                    "control.ZoomControl",
                    "control.FullscreenControl",
                ]}
            ></Map>
        </YMaps>
    );
    function escapeHtml(text) {
        var map = {
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            '"': "&quot;",
            "'": "&#039;",
            " ": "&nbsp;",
        };

        return text.replace(/[&<>"' ]/g, function (m) {
            return map[m];
        });
    }
    useEffect(() => {
        if (params.istMap !== null) {
            setDefoltcor(
                JSON.parse(params.istMap.coordinates)
            );
            
            setmap(
                <YMaps
                    query={{
                        apikey: "92e27a44-279d-48bf-be97-d95e84154e5e",
                        lang: "ru_RU",
                        ns: "use-load-option",
                        load: "Map,Placemark,control.ZoomControl,control.FullscreenControl,geoObject.addon.balloon",
                    }}
                    className="map_content_map"
                >
                    <Map
                        height={"500px"}
                        width={"100%"}
                        state={{
                            center: JSON.parse(params.istMap.coordinates),
                            zoom: 14,
                            controls: [
                                "zoomControl",
                                "fullscreenControl",
                            ],
                        }}
                        modules={[
                            "control.ZoomControl",
                            "control.FullscreenControl",
                        ]}
                    >
                        <Placemark
                            modules={[
                                "geoObject.addon.balloon",
                            ]}
                            geometry={JSON.parse(params.istMap.coordinates)}
                            properties={{
                                balloonContent:
                                    JSON.stringify(
                                        `<img src=${
                                            params.istMap
                                                .img
                                        }  /><p><b>${escapeHtml(
                                            params.istMap
                                                .name
                                        )}</b></p><p>${escapeHtml(
                                            params.istMap
                                                .address
                                        )}</p>`
                                    ),
                                iconCaption: escapeHtml(
                                    params.istMap.name
                                ),
                            }}
                            options={{
                                // preset: 'islands#greenDotIconWithCaption',
                                // iconColor: '#aeca3b',
                                iconLayout: "default#image",
                                iconImageHref: `/img/map/${params.istMap.type}.svg`,
                                iconImageSize: [40, 52],
                                // Смещение левого верхнего угла иконки относительно
                                // её "ножки" (точки привязки).
                                // iconImageOffset:JSON.parse(params.istMap.coordinates),
                                controls: [],
                            }}
                        />
                    </Map>
                </YMaps>
            );
        }
    }, [params]);
    useEffect(() => {
    
    }, [defoltcor])
    
    // apikey:"92e27a44-279d-48bf-be97-d95e84154e5e",
    return (
        <section className="map" id="mapBlock">
            <div className="container flex">
                <h2 className="h2">
                    Карта достопримечательностей <br />
                </h2>
                <p className="t20">
                </p>
                <div className="map_content">{ map }</div>
            </div>
        </section>
    );
}
