import { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Switch from "../modules/swich";
import { URL_API } from "../data/param";
import useDataFetch from "../data/ajsx";
let responsive = {
    0: { items: 1, itemsFit: "containt" },
    320: { items: 2, itemsFit: "containt" },
    640: { items: 3, itemsFit: "containt" },
    960: { items: 4, itemsFit: "containt" },
    1280: { items: 5, itemsFit: "containt" },
};
if (window.innerWidth <= 750) {
    responsive = {
        0: { items: 1, itemsFit: "containt" },
    };
}

export default function OKrasnoyarsk(params) {
    const [isLoaded, error, defolt] = useDataFetch({
        url: "/api/?news=all",
    });
    const [items, setItems] = useState([]);
    useEffect(() => {
        setItems(defolt);
    }, [defolt]);
    const carousel = useRef(null);

    function moni(e) {
        if (e.item > 0) {
            document
                .querySelectorAll(
                    ".oKrasnoyarsk_menu_switch_item"
                )[0]
                .classList.remove("inactive");
        } else {
            document
                .querySelectorAll(
                    ".oKrasnoyarsk_menu_switch_item"
                )[0]
                .classList.add("inactive");
        }
        if (
            e.item >=
            document.querySelectorAll(
                ".oKrasnoyarsk_slider_item"
            ).length -
                3
        ) {
            document
                .querySelectorAll(
                    ".oKrasnoyarsk_menu_switch_item"
                )[1]
                .classList.add("inactive");
        } else {
            document
                .querySelectorAll(
                    ".oKrasnoyarsk_menu_switch_item"
                )[1]
                .classList.remove("inactive");
        }
    }
    return (
        <section className="oKrasnoyarsk" id="oKrasnoyarsk">
            <div className="container flex">
                <h2 className="h2">О Красноярском крае</h2>

                <div className="oKrasnoyarsk_menu flex">
                    <p className="oKrasnoyarsk_menu_text t20">
                        Красноярск — один из крупнейших
                        городов в Сибири, признаваемый
                        многими как её культурный и
                        туристический центр. Расположенный
                        на берегах живописного Енисея, он
                        славится своими историческими,
                        культурными и природными
                        достопримечательностями
                    </p>
                    <Switch
                        refSlide={carousel}
                        classRod={"oKrasnoyarsk"}
                    />
                </div>
                <div className="oKrasnoyarsk_slider flex">
                    {error ? (
                        <p>Ошибка</p>
                    ) : !isLoaded ? (
                        <p>Загрузка</p>
                    ) : (
                        <>
                            <div className="restor_slide_ops"></div>
                            <AliceCarousel
                                key="carousel"
                                mouseTracking
                                disableDotsControls
                                disableButtonsControls
                                items={items}
                                responsive={responsive}
                                ref={carousel}
                                onSlideChanged={moni}
                            >
                                {items.map((e, i) => (
                                    <SliderItem
                                        setActive={
                                            params.setActive
                                        }
                                        setData={
                                            params.setData
                                        }
                                        content={e.content}
                                        title={e.title}
                                        key={i}
                                        id={e.id}
                                        img={e.image}
                                    />
                                ))}
                            </AliceCarousel>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
}
function SliderItem(params) {
    function pop() {
        params.setActive(true);
        params.setData({
            name: params.title,
            img: params.img,
            content: params.content,
            local: null,
        });
    }
    return (
        <div
            onClick={() => pop()}
            data-value={params.id}
            className="oKrasnoyarsk_slider_item flex"
            title={params.title}
            style={{ background: `url(${params.img})` }}
        >
            <p className="oKrasnoyarsk_slider_item_name t18">
                {params.title}
            </p>
        </div>
    );
}
