import { useEffect, useRef, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import Switch from "../modules/swich";
import useDataFetch from "../data/ajsx";
let responsive = {
    0: { items: 1, itemsFit: "containt" },
    320: { items: 2, itemsFit: "containt" },
    640: { items: 3, itemsFit: "containt" },
  };
  if (window.innerWidth <=750) {
    responsive = {
      0: { items: 1, itemsFit: "containt" },
    };
  }

export default function TopRestor(params) {
    const [isLoaded, error, defolt] = useDataFetch({url:"/api/?restor=all"})

    const [items, setItems] = useState([]);
    const kuhen = useRef(null);
    useEffect(() => {
        setItems(defolt);
    }, [defolt]);

    function FilterMass(e) {
        let arrey = [];
        let arrey2 = [];
        let chek = "";
        let widKuh = "";
        document
            .querySelectorAll("input[name=chek]")
            .forEach((element) => {
                if (element.checked) {
                    chek = element.value;
                    // chek=element.value.split("-");
                }
            });
        document
            .querySelectorAll("input[name=widKuh]")
            .forEach((element) => {
                if (element.checked) {
                    widKuh = element.value;
                }
            });

        e.forEach((element) => {
                if (element.type_id.id === widKuh) {
                    arrey.push(element);
                }
        });
        if (arrey.length === 0) {
            arrey = defolt;
        }
        console.log(chek);
        switch (chek) {
            case "500":
                console.log("kj");
                arrey.forEach((e) => {
                    if (Number(e.average_cheque) <= 500) {
                        arrey2.push(e);
                    }
                });
                break;
            case "500-1500":
                console.log("kj");
                arrey.forEach((e) => {
                    if (
                        Number(e.average_cheque) < 1500 &&
                        Number(e.average_cheque) > 500
                    ) {
                        arrey2.push(e);
                    }
                });
                break;
            case "1500":
                console.log("kj");
                arrey.forEach((e) => {
                    if (
                       
                        Number(e.average_cheque) > 1500
                    ) {
                        arrey2.push(e);
                    }
                });
                break;
            case "2000":
                console.log("kj");
                arrey.forEach((e) => {
                    if (Number(e.average_cheque) > 2000) {
                        arrey2.push(e);
                    }
                });
                break;
            default:
                break;
        }
        console.log(arrey);
        console.log(arrey2);
        if (arrey.length !== 0) {
            if (arrey2.length !== 0) {
                setItems(arrey2);
            } else {
                setItems(arrey);
            }
        } else {
            setItems(defolt);
        }
    }


    function moni(e) {
        if (e.item > 0) {
            document
                .querySelectorAll(
                    ".oKrasnoyarsk_menu_switch_item"
                )[4]
                .classList.remove("inactive");
        } else {
            document
                .querySelectorAll(
                    ".oKrasnoyarsk_menu_switch_item"
                )[4]
                .classList.add("inactive");
        }
        if (
            e.item >=
            document.querySelectorAll(".restor_slide_item")
                .length -
                4
        ) {
            document
                .querySelectorAll(
                    ".oKrasnoyarsk_menu_switch_item"
                )[5]
                .classList.add("inactive");
        } else {
            document
                .querySelectorAll(
                    ".oKrasnoyarsk_menu_switch_item"
                )[5]
                .classList.remove("inactive");
        }
    }

    return (
        <section className="restor" id="restor">
            <div className="container flex">
                <div className="restor_menu">
                    <h2 className="h2">
                        ТОП лучших ресторанов и кафе
                        Красноярского края
                    </h2>
                </div>

                <div className="restor_menu">
                    <div className="restor_menu">
                        <div className="restor_menu_chek flex">
                            <p className="t18">
                                средний чек
                            </p>
                            <div className="restor_menu_chek_list flex">
                                <label
                                    htmlFor="chek_0"
                                    className="t16"
                                >
                                    <input
                                        type="radio"
                                        name="chek"
                                        id="chek_0"
                                        defaultChecked
                                        defaultValue={"0"}
                                        onClick={() =>
                                            FilterMass(
                                                defolt
                                            )
                                        }
                                    />
                                    любой
                                </label>
                                <label
                                    htmlFor="chek_1"
                                    className="t16"
                                >
                                    <input
                                        type="radio"
                                        name="chek"
                                        id="chek_1"
                                        defaultValue={"500"}
                                        onClick={() =>
                                            FilterMass(
                                                defolt
                                            )
                                        }
                                    />
                                    до 500 руб.
                                </label>
                                <label
                                    htmlFor="chek_2"
                                    className="t16"
                                >
                                    <input
                                        type="radio"
                                        name="chek"
                                        id="chek_2"
                                        defaultValue={
                                            "500-1500"
                                        }
                                        onClick={() =>
                                            FilterMass(
                                                defolt
                                            )
                                        }
                                    />
                                    500-1500 руб
                                </label>
                                <label
                                    htmlFor="chek_3"
                                    className="t16"
                                >
                                    <input
                                        type="radio"
                                        name="chek"
                                        id="chek_3"
                                        defaultValue={
                                            "1500"
                                        }
                                        onClick={() =>
                                            FilterMass(
                                                defolt
                                            )
                                        }
                                    />
                                    от 1500 руб
                                </label>
                            </div>
                        </div>
                        <div className="restor_menu_chek flex">
                            <p className="t18">
                                Кухни
                            </p>
                            <div className="restor_menu_chek_list flex">
                                <label
                                    className="restor_menu-left_switch_item flex"
                                    htmlFor="widKuh_1"
                                >
                                    <input
                                        type="radio"
                                        name="widKuh"
                                        id="widKuh_1"
                                        defaultValue={0}
                                        defaultChecked={
                                            true
                                        }
                                        onClick={() =>
                                            FilterMass(
                                                defolt
                                            )
                                        }
                                    />
                                    {/* <div className="restor_menu-left_switch_item_img flex">
                                        <img
                                            src={
                                                process.env
                                                    .PUBLIC_URL +
                                                "/img/restor/all.png"
                                            }
                                            alt=""
                                        />
                                    </div> */}
                                    <p className="restor_menu-left_switch_item_text t16">
                                        Все
                                    </p>
                                </label>
                                <label
                                    className="restor_menu-left_switch_item flex"
                                    htmlFor="widKuh_2"
                                >
                                    <input
                                        type="radio"
                                        name="widKuh"
                                        defaultValue={2}
                                        id="widKuh_2"
                                        onClick={() =>
                                            FilterMass(
                                                defolt
                                            )
                                        }
                                    />
                                    {/* <div className="restor_menu-left_switch_item_img flex">
                                        <img
                                            src={
                                                process.env
                                                    .PUBLIC_URL +
                                                "/img/restor/2.png"
                                            }
                                            alt=""
                                        />
                                    </div> */}
                                    <p className="restor_menu-left_switch_item_text t16">
                                        Сибирская
                                    </p>
                                </label>
                                <label
                                    className="restor_menu-left_switch_item flex"
                                    htmlFor="widKuh_3"
                                >
                                    <input
                                        type="radio"
                                        name="widKuh"
                                        defaultValue={3}
                                        id="widKuh_3"
                                        onClick={() =>
                                            FilterMass(
                                                defolt
                                            )
                                        }
                                    />
                                    {/* <div className="restor_menu-left_switch_item_img flex">
                                        <img
                                            src={
                                                process.env
                                                    .PUBLIC_URL +
                                                "/img/restor/3.png"
                                            }
                                            alt=""
                                        />
                                    </div> */}
                                    <p className="restor_menu-left_switch_item_text t16">
                                        Европейская
                                    </p>
                                </label>
                                <label
                                    className="restor_menu-left_switch_item flex"
                                    htmlFor="widKuh_4"
                                >
                                    <input
                                        type="radio"
                                        name="widKuh"
                                        defaultValue={4}
                                        id="widKuh_4"
                                        onClick={() =>
                                            FilterMass(
                                                defolt
                                            )
                                        }
                                    />
                                  
                                    <p className="restor_menu-left_switch_item_text t16">
                                        Азиатская
                                    </p>
                                </label>
                                <label
                                    className="restor_menu-left_switch_item flex"
                                    htmlFor="widKuh_5"
                                >
                                    <input
                                        type="radio"
                                        name="widKuh"
                                        defaultValue={9}
                                        id="widKuh_5"
                                        onClick={() =>
                                            FilterMass(
                                                defolt
                                            )
                                        }
                                    />
                                  
                                    <p className="restor_menu-left_switch_item_text t16">
                                        Кофейни
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="restor_menu_switchSlider">
                    <Switch refSlide={kuhen} classRod={'restor'}/>

                    </div>
                </div>

                <div className="restor_slide">
                    {error ? (
                        <p>Ошибка</p>
                    ) : !isLoaded ? (
                        <p>Загрузка</p>
                    ) : (
                        <>
                            <div className="restor_slide_ops"></div>
                            <AliceCarousel
                                key="kuhen"
                                responsive={responsive}
                                // autoWidth
                                mouseTracking
                                disableDotsControls
                                disableButtonsControls
                                ref={kuhen}
                                onSlideChanged={moni}
                            >
                                {items.map((e, i) => (
                                    <RestorItem
                                        key={i}
                                        type_id={e.type_id}
                                        address={e.address}
                                        setIstMap={params.setIstMap}
                                        coordinates={e.coordinates}
                                        type_place={
                                            e.type_place
                                        }
                                        chek={
                                            e.average_cheque
                                        }
                                        name={e.name}
                                        img={e.number_foto}
                                    />
                                ))}
                            </AliceCarousel>
                        </>
                    )}
                </div>
            </div>
        </section>
    );
}

function RestorItem(params) {
    let w = {
        coordinates:params.coordinates,
        img:`${params.img}`,
        name:params.name,
        type:'cafe',
        address:params.address

    }
    return (
        <div className="restor_slide_item">
            <div className="restor_slide_item_box">
                <div
                    style={{
                        background: `url(${
                            params.img
                        })`,
                    }}
                    className="restor_slide_item_img"
                >
                    <div className="restor_slide_item_img_flags flex">
                        {/* <p className="t16">
                            {params.type_place}
                        </p> */}
                        {/* <div className="restor_slide_item_img_flags_type flex">
                            {params.type_id.map((e, i) => (
                                <div
                                    key={i}
                                    className="restor_slide_item_img_flags_type_item flex"
                                >
                                    <img
                                        src={
                                            process.env
                                                .PUBLIC_URL +
                                            `/img/restor/${e.id}.png`
                                        }
                                        alt={e.name}
                                    />
                                </div>
                            ))}
                        </div> */}
                    </div>
                </div>
                <div className="restor_slide_item_info flex">
                    <h4 className="restor_slide_item_name t20">
                        {params.name}
                    </h4>
                    <div className="restor_slide_item_info_item flex">
                        <a href="#mapBlock" onClick={()=>params.setIstMap(w)} className="restor_slide_item_info_item_lab">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/restor/icon/local.png"
                                }
                                alt="Адрес заведения"
                            />
                            {params.address}
                        </a>
                        {/* <div className="restor_slide_item_info_item_lab">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/img/restor/icon/type.png"
                            }
                            alt="Ресторан"
                        />
                        Ресторан
                    </div> */}
                        <div className="restor_slide_item_info_item_lab">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/img/restor/icon/kuh.png"
                                }
                                alt="Европейская кухня"
                            />
                            Кухни:{" "}
                                <span
                                    className="kuh"
                                >
                                    {" "}
                                    {params.type_id.name}
                                </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="restor_slide_item_footer flex">
                Средний чек {params.chek} руб.
            </div>
        </div>
    );
}
